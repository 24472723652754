.menu-items-list {
    list-style: none;
    display: flex;
    flex-direction: row;
}

.menu-items-list li {
    padding-right: 11px;
}

.link-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none !important;
}

.link-button:hover {
    opacity: 1;
    text-decoration: underline !important;
}